import React, { useEffect, useRef, useState } from 'react';
// import './NavMenu.css';
import { Redirect } from 'react-router';
import { environment } from './environments/environment.prod';
import { NavbarItem } from './NavbarItem';
import Api from './Services/CommonServices';
const app_name = environment.appName;
const commonService = new Api();
export function NavMenu()  {
  const shopOrigin = useRef('');
  const [shopExist,setShopExist] = useState();
  const [frame,setFrame] = useState();
  const [chargeStatus,setChargeStatus] = useState('');
  useEffect(() => {
    shopOrigin.current = commonService.QueryStringToJSON();
    getShopExists();
    // console.log("IFrame Status =",window.self !== window.top);
    setFrame(window.self !== window.top);
    if(shopExist === true)
    {
      commonService.chargeStatus(shopOrigin.current.shop)
      .then(res => {
        setChargeStatus(res);
      })
    }
    
  }, []);

  function getShopExists() {
    commonService.checkIsShopExits(shopOrigin.current.shop)
      .then(res => {
        if (res === true) {
          getChargeStatus();
          setShopExist(true);
        }
        else{
          setShopExist(false);
        }
        
      })
  }
  function getChargeStatus() {
    commonService.chargeStatus(shopOrigin.current.shop)
      .then(res => { 
        if(res != undefined)
        {setChargeStatus(res);}
      })
  }

  // if (shopExist === true && chargeStatus === "Pending") {
  //   return (commonService.addCharge(shopOrigin.current.shop)
  //     .then(res => {
  //       window.open(res, '_top');

  //     }, err => {
  //       // console.log(`err = ${JSON.stringify(err)}`);
  //     }));
  // } else
   if (shopExist === false) {
    if (shopOrigin.current.shop != null) {
      return (commonService.generateAuthUrl(shopOrigin.current.shop)
        .then(res => {
          window.open(res, '_top');
        }, err => {
          // console.log(`err = ${JSON.stringify(err)}`);
        }));
    } else if (window.location.pathname === "/privacy") {
      return (
        <Redirect to='/privacy' />
      )
    }
    else {
      return (
        <Redirect to='/verify-shop' />
      )
    }
  }
  else {
    return frame === false && shopExist === true ? (window.open("https://"+shopOrigin.current.shop + "/admin/apps/"+app_name +  window.location.pathname,'_top'))
                          : (<NavbarItem/>)   
    // return shopExist === true && chargeStatus === "Active" ? (window.open("https://" + shopOrigin.current.shop + "/admin/apps/" + app_name + window.location.pathname, '_top'))
    //   : (<NavbarItem />)
  }
    
    }