import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout1 } from './components/Layout1';
import { VerifyShop } from './components/VerifyShop';
import { requireAuth } from './components/Auth/auth';
import { AppProvider } from '@shopify/polaris';
// import './custom.css'
import { PrivacyPolicy } from './components/PrivacyPolicy';
// import { QboSetting } from './components/QuickbooksSetup/QboSetting';
// import { Setting } from './components/Setting';
import { Setup } from './components/Setup';
// import { AuditLog } from './components/AuditLog';
// import { Audit_log } from './components/audit-log';
const auth = new requireAuth();
export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <AppProvider>
      <Layout1>
        {/* <Route exact path='/' component={Setting} appProps={  auth.canActivate() }/> */}
        <Route exact path='/' component={Setup}  appProps={  auth.canActivate() }/>
        <Route path='/verify-shop' component={VerifyShop} />
        {/* <Route path='/audit-log' component={Audit_log}  /> */}
      </Layout1>
      <Route path='/privacy' component={PrivacyPolicy} />
      </AppProvider>
    );
  }
}
