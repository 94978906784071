import React from 'react';
import { FooterHelp } from "@shopify/polaris";
export function EmailSupport() {


    return  (

        <FooterHelp>
        For any queries{' '}
        {/* <Link url="mailto:support@claritytechnologies.com?subject=Support:" target='_new' > */}
          email support at support@claritytechnologies.com
        {/* </Link> */}
      </FooterHelp>
    );
}