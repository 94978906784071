import React from 'react';
import enTranslations from '@shopify/polaris/locales/en.json';
import { AppProvider } from '@shopify/polaris';
// import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
// import { Link, Redirect } from 'react-router-dom';
  export function NavbarItem() {

    // const[loader,showLoader,hideLoader] = useLoader();
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    
    return (
    <AppProvider i18n={enTranslations}>
      <div style={style}>
        {/* {loader}  */}
        </div>
     {/* <Redirect to='/settings'/> */}
     {/* {window.open("https://the-frame-looter.myshopify.com/admin/apps/dtdc-courier",'_top')} */}
    {/*   <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="#">Tallify</NavbarBrand>
            <NavbarToggler className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse"  navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/setting">Tally Setup</NavLink>
                </NavItem>
                <NavItem>
                <NavLink tag={Link} className="text-dark" to="/qbo-setting">Quickbooks Setup</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/privacy">Privacy</NavLink>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>*/}
    </AppProvider>
  );
}