import axios from 'axios';
import { environment } from '../environments/environment.prod';
const endpoint = environment.serverUrl;
class Api {

  generateAuthUrl = (shopUrl) => {
    return axios
      .get(endpoint + '/api/Shop/get-auth-url?storeUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }


  createRecurringCharge(shopUrl) {
    return axios
      .get(endpoint + '/api/RecurringCharge/add?shopUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }
  verifyShop = (queryString) => {
    if (!queryString) {
      queryString = this.queryString;
    }
    return axios
      .get(endpoint + '/api/Shop/verify-request' + queryString)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }

  checkIsShopExits = (shopUrl) => {
    return axios
      .get(endpoint + '/api/Shop/IsStoreExists?storeUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }

  chargeStatus = (shopUrl) => {
    return axios
      .get(endpoint + '/api/RecurringCharge/chargestatus?shopUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }
  addCharge = (shopUrl) => {
    return axios
      .get(endpoint + '/api/RecurringCharge/create?shopUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }
  getEventsOfShop = (shopUrl, pageNumber) => {
    // console.log(shopUrl);
    return axios
      .get(endpoint + '/api/Event/events-of-shop?shopUrl=' + shopUrl + '&pageNumber=' + pageNumber)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data

          console.log('Events =', data);
          return data

        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }

  getStoredDateTime = (shopUrl) => {
    // console.log(shopUrl);
    return axios
      .get(endpoint + '/api/StoreConfig/stored-time?shopUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data

          // console.log('dateTime =',data);
          return data

        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }

  getStoreSetup = (shopUrl) => {
    // console.log(shopUrl);
    return axios
      .get(endpoint + '/api/StoreConfig/storeconfigs-of-shop?shopUrl=' + shopUrl)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data

          // console.log('storeSetup =',data);
          return data

        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }

  getEventsBySubjectType = (shopUrl, subjectType) => {
    // console.log(shopUrl);
    return axios
      .post(endpoint + '/api/event/subject-type?shopUrl=' + shopUrl + '&subjectType=' + subjectType)
      .then(function (response) {
        // console.log(response)
        if (response.status === 200 && response != null) {
          var data = response.data

          console.log('EventsBySubjectType =', data);
          return data

        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return [] // Return empty array in case error response.
      })
  }
  // getOrders = (shopUrl) => {
  //   // console.log(shopUrl);
  //   return axios
  //     .get(endpoint+'/api/Order?shopUrl='+ "tpt-staging.myshopify.com")
  //     .then(function(response) {
  //       // console.log(response)
  //       if (response.status === 200 && response != null) {
  //         var data = response.data

  //         console.log('tally godowns =',data);
  //         return data

  //       } else {
  //         throw new Error('Empty data')
  //       }
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //       return [] // Return empty array in case error response.
  //     })
  // }
  getEventsByPaging(shopUrl, pageNumber) {
    return axios
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .get(endpoint + '/api/Event/events-of-shop?shopUrl=' + shopUrl + '&pageNumber=' + pageNumber)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          var data = response.data
          console.log('EventsByPaging =', data);
          return data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }
  sendTestEmail(shopUrl) {
    return axios
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl='+shopUrl+'&pageNo='+pageNo +'&pageSize='+pageSize +'&sortOrder='+sortOrder)
      // .get(endpoint+'/api/DistanceMatrix/sort?shopUrl=di-staging.myshopify.com&pageNo='+pageNo +'&pageSize='+pageSize+'&sortOrder='+sortOrder)
      .get(endpoint + '/api/Event/testmail?shopUrl=' + shopUrl)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  getShop(shopUrl) {
    return axios
      .get(endpoint + '/api/Shop?storeUrl=' + shopUrl)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          // var data = response.data
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  // getEventsForSearch(shopUrl,searchText,pageNumber,pageSize,orderby){
  //   return axios
  //   .get(endpoint+'/api/event/search?shopUrl=' + shopUrl + '&searchFor=' + searchText + '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&orderby=' + orderby)
  //   .then(function(response) {
  //     if (response.status === 200 && response != null) {
  //       return response.data
  //     } else {
  //       throw new Error('Empty data')
  //     }
  //   })
  //   .catch(function(error) { 
  //     console.log(error)
  //     return [] 
  //   })
  // }

  getEventsCount(shopUrl) {
    return axios
      .get(endpoint + '/api/Event/get-events-count?shopUrl=' + shopUrl)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  getEventsForSearch(shopUrl, page_info) {
    return axios
      .get(endpoint + '/api/Event/get-all-events?shopUrl=' + shopUrl + '&page_info=' + page_info)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  getAllPermissions() {
    return axios
      .get(endpoint + '/api/PermissionManagement')
      .then(function (response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }
  getSelectedPermissions(shopUrl) {
    return axios
      .get(endpoint + '/api/PermissionManagement/by-shop?shopUrl=' + shopUrl)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          return response.data
        } else {
          throw new Error('Empty data')
        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }


  getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  QueryStringToJSON() {
    let queryString = "";
    queryString = window.location.search;
    // console.log(`query string = ${queryString}`);
    if (!queryString) {
      queryString = this.queryString;
    }
    let result = {};
    if (queryString) {
      this.queryString = queryString;
      const pairs = queryString.replace('?', '').split('&');

      pairs.forEach(function (pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
      });
    }

    return JSON.parse(JSON.stringify(result));
  }

}
export default Api
