import React, { useCallback, useState, useEffect, useRef } from 'react';
import { AppProvider, Button, Card, ChoiceList, Form, FormLayout, Layout, Page, PageActions, Select, SettingToggle, Subheading, TextStyle } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import useSuccessToast from './Toast/UseSuccessToast';
import useErrorToast from './Toast/UseErrorToast';
import { environment } from './environments/environment.prod';
import { EmailSupport } from './EmailSupport';
import useLoader from './Loader/UseLoader';
import * as storage from "./Auth/storage";
import moment from 'moment';
import Api from './Services/CommonServices';
const api = new Api();
const endpoint = environment.serverUrl;
export function Setup() {
  const shopOrigin = useRef('');
  const [active, setActive] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();
  const [toastMarkup, showToast] = useSuccessToast();
  const [toastErrorMarkup, showErrorToast] = useErrorToast();
  const handleToggle = useCallback(() => setActive((active) => !active), []);
  const contentStatus = active ? 'Disable' : 'Enable';
  const textStatus = active ? 'enabled' : 'disabled';
  const [selectedTime, setSelectedTime] = useState('');
  const handleTimeChange = useCallback((value) => setSelectedTime(value), []);
  const [btnLoad, setBtnLoad] = useState(false);
  const [checked, setChecked] = useState([]);
  const handleCheckChange = useCallback((newChecked) => setChecked(newChecked), []);
  const [permissions, setPermissions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [shop, setShop] = useState({});
  const [time, setTime] = useState([]);
  const [hide, setHide] = useState(false);
  useEffect(() => {
    shopOrigin.current = api.QueryStringToJSON();
    getStoredDateTime();
    getStoreSetup();
    getShop();
    getAllPermissions();
    getSelectedPermissions();
  }, []);

  const handleFormSubmit = (evt) => {
    evt.preventDefault();
    var equals = selected.length === checked.length && selected.every((e, i) => e.value === checked[i].value);
    if (equals === false) { getAuthUrlWithUpdatedScopes(); }
    addOrUpdateStoreConfig();
  }

  const handleFormToggle = (evt) => {
    evt.preventDefault();
    StoreStatus();
  }

  async function getStoredDateTime() {
    showLoader();
    api.getStoredDateTime(shopOrigin.current.shop)
      .then(items => {
        setTime(items)
        // hideLoader();
      });
  }
  async function getStoreSetup() {
    // showLoader();
    api.getStoreSetup(shopOrigin.current.shop)
      .then(items => {
        items.storeStatus === 'Enable' ? setActive(true) : setActive(false)
        var a = moment(items.selectedTime).format('MMMM Do YYYY') === moment("0001-01-01 00:00:00").format('MMMM Do YYYY')
        setHide(a);
        setSelectedTime(items.selectedTime);
        // hideLoader();
      });
  }
  async function getShop() {
    // showLoader();
    api.getShop(shopOrigin.current.shop)
      .then(items => {
        setShop(items)
        hideLoader();
      });
  }
  async function getAllPermissions() {
    // showLoader();
    api.getAllPermissions()
      .then(items => {
        setPermissions(items)
        // hideLoader();
      });
  }

  async function getSelectedPermissions() {
    var checkedList = [];
    var selectedList = [];
    // showLoader();
    api.getSelectedPermissions(shopOrigin.current.shop)

      .then(items => {
        items.map(s => {
          if (s.selected === true) {
            checkedList.push(s.value)
            selectedList.push(s.value);
          }
        })
        setChecked(checkedList);
        setSelected(selectedList);
        setSelectedPermissions(items)

        // hideLoader();
      });
  }

  async function addOrUpdateStoreConfig() {
    setBtnLoad(true);
    var storeStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    const data = {
      selectedTime: selectedTime,
      shop: shopOrigin.current.shop,
      storeStatus: storeStatus
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(endpoint + '/api/StoreConfig/add-update', requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          setBtnLoad(false);
          showToast();
          getStoreSetup();
          return response
        } else {
          setBtnLoad(false);
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }

  function searchForSelectedItem(v) {
    return selectedPermissions.filter(item => {
      return item.value === v

    })
  };

  function getAuthUrlWithUpdatedScopes() {
    var scopes = []
    checked.map(item => {
      var d = searchForSelectedItem(item)
      scopes.push(d);
    })
    const data = []
    scopes.map(items => {
      items.map(item => {
        var da = {
          Title: item.title,
          Value: item.value,
          Description: item.description,
          Selected: true
        };
        data.push(da);
      })
    })
    // console.log("config=",data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    fetch(endpoint + '/api/shop/get-auth-url-with-scopes?shopUrl=' + shopOrigin.current.shop, requestOptions)
    .then(response => response.json())
    .then(data =>{ console.log("authUrl=",data);window.open(data.authUrl, '_top')});
      // .then(data => window.open(data, '_top'));
  }

  async function StoreStatus(value) {
    var storeStatus = contentStatus === "Disable" ? "Enable" : "Disable";
    const data = {
      shop: shopOrigin.current.shop,
      storeStatus: storeStatus
    };
    // console.log("config=",data);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };
    // console.log(requestOptions)
    fetch(endpoint + '/api/StoreConfig/StoreStatus?shopUrl=' + shopOrigin.current.shop, requestOptions)
      .then(function (response) {
        if (response.status === 200 && response != null) {
          showToast();
          return response
        } else {
          showErrorToast();
          throw new Error('Empty data')

        }
      })
      .catch(function (error) {
        console.log(error)
        return []
      })
  }


  async function sendTestEMail() {
    showLoader();
    api.sendTestEmail(shopOrigin.current.shop)
      .then(items => {
        hideLoader();
      });
  }


  const scopes = []
  permissions.forEach(per => {
    const data = {
      value: per.value, label: per.title
    };
    scopes.push(data)
  })
  // console.log("permisssions options =" ,scopes)
  const timeOptions = []
  time.forEach(t => {
    var timeConvert = moment(t).format('HH.mm');
    const data = {
      value: t, label: timeConvert
    };
    timeOptions.push(data)
  })
  // console.log("store status=",active)
  return (

    <AppProvider i18n={enTranslations}>
      <Page>
        <Layout>
          <Layout.AnnotatedSection
            title="General"
            description="Customize your app settings.">

            <Card>
              <Form onSubmit={handleFormToggle}>
                <SettingToggle
                  action={{
                    content: contentStatus,
                    onAction: handleToggle,
                    submit: true
                  }}
                  enabled={active}>
                  Audit Wuff is <TextStyle variation="strong">{textStatus}</TextStyle>.
</SettingToggle>
              </Form>
            </Card>
            <Form onSubmit={handleFormSubmit}>
              <Card></Card>
              <Card sectioned>
                <FormLayout>
                  <div><Subheading>PERMISSIONS</Subheading><br></br>
                    {/* <Caption>Select the scopes</Caption> */}
                    <ChoiceList
                      allowMultiple
                      title="Select the scopes"
                      choices={scopes}
                      selected={checked}
                      onChange={handleCheckChange}
                    />
                  </div>
                  <Select
                    label="Select a time to receive daily emails."
                    options={timeOptions}
                    onChange={handleTimeChange}
                    value={selectedTime}
                    disabled={false}
                  />
                  {hide === true || active === false ? "" :
                    <p>We’ll send this email at <b>{moment(selectedTime).format('HH.mm')} ({shop.iana_timezone}) </b></p>
                  }
                  <p>Send yourself a test email. We'll send this email to {shop.email}. <Button size="slim" onClick={sendTestEMail}>Send Test Email</Button></p>
                </FormLayout>
              </Card>
              <PageActions
                primaryAction={{
                  content: 'Save',
                  loading: btnLoad,
                  submit: true,
                }} />
            </Form>
            <EmailSupport />
          </Layout.AnnotatedSection>
        </Layout>
        {toastMarkup}
        {toastErrorMarkup}
        {loader}
      </Page>

    </AppProvider>
  );
}