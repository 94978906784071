import React, { useState,useEffect,useCallback} from 'react';
import { AppProvider,FormLayout,TextField,PageActions,Layout,Form, Page, Stack, DisplayText, Button, FooterHelp } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import Api from './Services/CommonServices';
import { Link } from 'react-router-dom';
// import { environment } from './environments/environment.prod';
const api = new Api();
// let storeUrl = "";

export function VerifyShop() {
const [shopUrl, setShopUrl] = useState('');
var queryParams = {};
const handleChange = useCallback((newValue) => setShopUrl(newValue), []);

useEffect(() => {
  getStoreUrl();
}, []);

async function generateAuthUrl() {
    api.generateAuthUrl(shopUrl)
    .then(res => {
      // console.log(`res = ${res}`);
      window.open(res, '_top');
    }, err => {
      console.log(`err = ${JSON.stringify(err)}`);
    });
  }
  const handleFormSubmit =(evt) =>{
    evt.preventDefault();
    getStarted()
    // console.log(shopUrl);
  }

async function getStoreUrl()
{
 console.log(`queryparams = ${JSON.stringify(api.QueryStringToJSON())}`);
 queryParams = api.QueryStringToJSON();
 if (queryParams && queryParams.shop) {
  setShopUrl(queryParams.shop);
  //  console.log(`shopUrl = ${shopUrl}`);
 }
}
async function getStarted() {
    shopUrl.trim();
    generateAuthUrl();
  }
    return (

      <AppProvider i18n={enTranslations}>
        <Page>
       <Stack>
          <Stack.Item fill>

          </Stack.Item>
          <Stack.Item>
            <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column', marginBottom: '1rem' }} >
              {/* <img src={logo} width={100} /> */}
              <DisplayText size="extraLarge">Audit Wuff</DisplayText>
              {/* <DisplayText size="medium">Sync Tally ERP 9 or Quickbooks Online with Shopify.</DisplayText> */}
            </div>
          </Stack.Item>
          <Stack.Item fill>

          </Stack.Item>
        </Stack>
        <br />
        <Layout>
          <Layout.Section>
            <div style={{ textAlign: 'center', margin: '2rem auto', alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', marginBottom: '1rem' }} >
              <DisplayText size="medium">Enter your store url to get started</DisplayText>
              <br></br>
              <Form onSubmit={handleFormSubmit}>
                <FormLayout>
                  <TextField
                    helpText="Example: awesome-store.myshopify.com"
                    type="text"
                    id="storeName"
                    placeholder='my-amazing-store'
                    value={shopUrl}
                    onChange={handleChange}
                    connectedRight={<Button submit primary>Install</Button>}
                    suffix=".myshopify.com"
                    autoComplete="off"
                  />

                </FormLayout>
              </Form>
            </div>
          </Layout.Section>
        </Layout>
        <FooterHelp>
          Learn more about{' '}
          <Link to='/privacy'>
          Privacy
          </Link>
        </FooterHelp>
        {/* </Provider> */}
        </Page>
      </AppProvider>


    );
  }

  export default VerifyShop
